import classNames from "classnames";
import * as React from "react";
import { VideoOverlay } from "../../components/VideoOverlay";
import "./ProgramSchedule.scss";

interface IProgramExpanderProps {
  title: string;
  children: React.ReactNode;
}

export const ProgramExpander: React.FunctionComponent<IProgramExpanderProps> = (
  props
) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div className={classNames("ProgramExpander", { expanded })}>
      <div className="HeaderBar" onClick={() => setExpanded(!expanded)}>
        <span className="Presenter">
          {props.title}
        </span>
        <div className={classNames("Expander", { expanded })} />
      </div>
      <div className={classNames("Body", { expanded })}>{props.children}</div>
    </div>
  );
};

interface IProgramScheduleProps {}

export const ProgramSchedule: React.FunctionComponent<IProgramScheduleProps> = (
  props
) => {
  const [video, setVideo] = React.useState("");

  return (
    <div
      className={classNames("ProgramSchedule", {
        showingVideo: Boolean(video),
      })}
    >
      <h2>CES 2024 Program Schedule</h2>
      <div className="Expanders">
        <h3>Panel 1: <strong>Justin Rose</strong></h3>
        <ProgramExpander
          title="AI and Robotics in Agriculture"
        >
          <div>
            <strong>Session Title:</strong> AI and Robotics in Agriculture
          </div>
          <div>
            <strong>Date:</strong> Tuesday, January 9, 2024
          </div>
          <div>
            <strong>Time:</strong> 1:00–1:40pm PST
          </div>
          <div>
            <strong>Location:</strong> Venetian Level 4 Lando 4302
          </div>
          <div>
            <strong>Session Overview:</strong> How can robotics and AI change
            how we plant, harvest, and manage crops? This panel will explore the
            AI revolution in the world of agriculture.
          </div>
          <div>
            <strong>Moderator:</strong> Mike Wolf, CEO and Founder, The Spoon
          </div>
          <div>
            <strong>Deere Panelist:</strong> Justin Rose, President, Lifecycle
            Solutions, Supply Management, and Customer Success
          </div>
          <div>
            <div>
              <strong>Other Panelists:</strong>
            </div>
            <ul>
              <li>
                Scott Komar, Senior Vice President of Global R&D, Driscoll’s
              </li>
              <li>Katherine Sizov, CEO & Co-Founder, Strella</li>
              <li>Elliot Grant, CEO, Mineral.ai</li>
            </ul>
          </div>
        </ProgramExpander>
        <h3>Panel 2: <strong>Sarah Schnickel</strong></h3>
        <ProgramExpander
          title="The Road to Resilience: Preparing Our Food and Water Supply Chain for the Future"
        >
          <div>
            <strong>Session Title:</strong> The Road to Resilience: Preparing
            Our Food and Water Supply Chain for the Future
          </div>
          <div>
            <strong>Date:</strong> Wednesday, January 10, 2024
          </div>
          <div>
            <strong>Time:</strong> 3:00–3:40pm PST
          </div>

          <div>
            <strong>Location:</strong> Venetian Level 4 Lando 4302
          </div>

          <div>
            <strong>Session Overview:</strong> Learn about proactive planning and innovative strategies to support the changing demographics, urbanization, and extreme climates that are impacting the sustainable food and water supply chain.
          </div>
          <div>
            <strong>Moderator:</strong> Adam Sharkawy, Co-Founder and Managing Partner, Material Impact
          </div>
          <div>
            <strong>Deere Panelist:</strong>Sarah Schnickel, Director of
            Emerging Technologies
          </div>
          <div>
            <div><strong>Other Panelists:</strong></div>
            <ul>

              <li>Cody Friesen, Founder and CEO, SOURCE Water</li>
              <li>Hiro Iwanaga, Co-Founder and CEO, Talus Renewables</li>

            </ul>
          </div>
        </ProgramExpander>
      </div>
      {video && (
        <VideoOverlay
          src={video}
          show={Boolean(video)}
          onOverlayClicked={() => setVideo("")}
        />
      )}
    </div>
  );
};

import classNames from "classnames";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../img/Logo.png";
import "./Header.scss";
interface IAppMenuProps {}

const AppMenu: React.FunctionComponent<IAppMenuProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  // const { launch } = React.useContext(TeaserStateContext);

  React.useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <div className="AppMenu">
      <div
        className={classNames("menu-toggle", { open })}
        role="button"
        onClick={() => setOpen(!open)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={classNames("menu", { open })}>
        <li className={location.pathname === "/" ? "active" : ""}>
          <Link to="/">Home</Link>
        </li>
        <li className={location.pathname === "/experts" ? "active" : ""}>
          <Link to="/experts">Meet The Experts</Link>
        </li>
        <li className={location.pathname === "/media" ? "active" : ""}>
          <Link to="/media">Resources</Link>
        </li>
      </ul>
    </div>
  );
};

export default AppMenu;

interface IHeaderProps {}

export const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  return (
    <header>
      <Link to="/">
        <img src={logo} alt="" />
      </Link>
      <AppMenu />
    </header>
  );
};

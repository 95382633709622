export const AttribLink = (props: {
  href: string;
  children: React.ReactNode;
}) => {
  return (
    <>
      &nbsp;
      <a
        className="attrib"
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    </>
  );
};

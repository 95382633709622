import * as React from "react";
import classNames from "classnames";
import { ModalContext } from "../../components/modal/Modal";
import {
  IImageResourcesProps,
  JdImageResource,
  urlRoot,
} from "./MediaResources";
import { ResourceCategory } from "./Media";

interface ImageResource {
  category: string;
  path: JdImageResource;
  label?: string;
}

export const ImageResources: React.FunctionComponent<IImageResourcesProps> = (
  props
) => {
  const [category, setCategory] = React.useState<
    ResourceCategory
  >("all");
  const { handleModal } = React.useContext(ModalContext);

  const imgList: ImageResource[] = [
    { category: "autonomy", path: "r4f167304_rrd_1x.jpg" },
    { category: "autonomy", path: "r4f167378_rrd_1x.jpg" },
    { category: "autonomy", path: "r4f167445_rrd_1x.jpg" },
    { category: "autonomy", path: "r4f175043_rrd_1x.jpg" },
    { category: "furrow-vision", path: "Planter_Furrow_Vision-1_1x.jpg" },
    { category: "furrow-vision", path: "Planter_Furrow_Vision-2_1x.jpg" },
    { category: "furrow-vision", path: "Planter_Furrow_Vision-3_1x.jpg" },
    { category: "see-and-spray", path: "r4f064278_rrd_1x.jpg" },
    { category: "see-and-spray", path: "SS-Profile_1x.jpg" },
    { category: "cotton-picker", path: "CottonPicker-1_1x.jpg" },
    { category: "cotton-picker", path: "CottonPicker-2_1x.jpg" },
    { category: "road-building", path: "Milling-1_1x.jpg" },
    { category: "road-building", path: "Milling-2_1x.jpg" },
    { category: "road-building", path: "SmartGrade-1_1x.jpg" },
    { category: "road-building", path: "SmartGrade-2_1x.jpg" },
    { category: "operations-center", path: "OperationsCenter_1x.jpg"}
  ]
    .filter((x) => Boolean(x.category))
    .filter((x) => x.category === category || category === "all");


  function labelFor(x: { category: string; path: JdImageResource }) {
    // const path = typeof x.path === "string" ? x.path : x.path.full;

    return "View";
  }

  return (
    <div className="ImageResources">
      <div className="filters">
        <div
          onClick={() => setCategory("all")}
          className={classNames({ active: category === "all" })}
        >
          All
        </div>
        <div
          onClick={() => setCategory("autonomy")}
          className={classNames({ active: category === "autonomy" })}
        >
          Autonomy
        </div>
        <div
          onClick={() => setCategory("furrow-vision")}
          className={classNames({ active: category === "furrow-vision" })}
        >
          FurrowVision™
        </div>

        <div
          onClick={() => setCategory("see-and-spray")}
          className={classNames({ active: category === "see-and-spray" })}
        >
          See &amp; Spray™ Ultimate
        </div>
        <div
          onClick={() => setCategory("cotton-picker")}
          className={classNames({ active: category === "cotton-picker" })}
        >
          Cotton Picker
        </div>
        <div
          onClick={() => setCategory("road-building")}
          className={classNames({ active: category === "road-building" })}
        >
          Road Building
        </div>
        <div
          onClick={() => setCategory("operations-center")}
          className={classNames({ active: category === "operations-center" })}
        >
          Operations Center™
        </div>

      </div>
      <div className={classNames("images-grid", category)}>
        {imgList.length === 0 && <div className="no-images">Coming Soon.</div>}
        {imgList.map((x) => (
          <div
            className="img-wrapper"
            key={typeof x.path === "string" ? x.path : x.path.full}
            onClick={() =>
              handleModal({
                lightbox: true,
                img: `${urlRoot}/img/${x.category}/${
                  typeof x.path === "string" ? x.path : x.path.full
                }`,
              })
            }
          >
            <div
              className={classNames("img-thumb", x.category)}
              style={{
                backgroundImage: `url(${urlRoot}/img/${x.category}/${
                  typeof x.path === "string" ? x.path : x.path.thumbnail
                })`,
              }}
            />
            <div className="thumb-overlay">
              <span>{labelFor(x)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import fb from "../img/social/logo-facebook.png"
import insta from "../img/social/logo-instagram.png"
import { ReactComponent as Twitter } from "../img/social/logo-x.svg"
import yt from "../img/social/logo-youtube.png"
import li from "../img/social/logo-linkedin.png"
import "./Footer.scss";

interface IFooterProps {
}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  const currentYear = new Date().getFullYear();
  return (<div className="footer">
  <div className="left">
    Copyright© {currentYear} Deere &amp; Company. All Rights Reserved
  </div>
  <div className="right">
    <div>Follow John Deere at CES®:</div>
    <div className="social">
      <a href="https://www.facebook.com/JohnDeereUSCA" target="_blank" rel='noreferrer'><img src={fb} alt=""/></a>
      <a href="https://www.instagram.com/johndeere/?hl=en" target="_blank" rel='noreferrer'><img src={insta} alt=""/></a>
      <a href="https://x.com/JohnDeere" target="_blank" rel='noreferrer'><Twitter /></a>
      <a href="https://www.youtube.com/user/JohnDeere" target="_blank" rel='noreferrer'><img src={yt} alt=""/></a>
      <a href="https://www.linkedin.com/company/john-deere/" target="_blank" rel='noreferrer'><img src={li} alt=""/></a>
    </div>
  </div>
</div>);
};


export default Footer;
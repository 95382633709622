import * as React from "react";
import classNames from "classnames";
import { ModalContext } from "../../components/modal/Modal";
import { urlRoot } from "./MediaResources";
import { ResourceCategory } from "./Media";

export interface IVideoResourcesProps {}

export const VideoResources: React.FunctionComponent<IVideoResourcesProps> = (
  props
) => {
  const [category, setCategory] = React.useState<ResourceCategory>("all");
  const { handleModal } = React.useContext(ModalContext);

  const vidList: {
    category: string;
    path: string;
  }[] = [
    { category: "autonomy", path: "VideoFive_Front" },
    { category: "autonomy", path: "VideoFour_DroneThree" },
    { category: "autonomy", path: "VideoSeven" },
    { category: "autonomy", path: "AutonomyVideo" },
    { category: "furrow-vision", path: "FurrowVision FINAL" },
    { category: "see-and-spray", path: "SaSUBRoll01" },
    { category: "see-and-spray", path: "SaSUBRoll02" },
    { category: "see-and-spray", path: "SaSUBRoll03" },
    { category: "cotton-picker", path: "Cotton B-Roll" },
    { category: "road-building", path: "MillAssist" },
    { category: "road-building", path: "SmartGrade FINAL" },
    { category: "operations-center", path: "OpsCenterVideo" },
  ]
    .filter((x) => Boolean(x.category))
    .filter((x) => x.category === category || category === "all");

  return (
    <div className="VideoResources">
      <div className="filters">
        <div
          onClick={() => setCategory("all")}
          className={classNames({ active: category === "all" })}
        >
          All
        </div>
        <div
          onClick={() => setCategory("autonomy")}
          className={classNames({ active: category === "autonomy" })}
        >
          Autonomy
        </div>
        <div
          onClick={() => setCategory("furrow-vision")}
          className={classNames({ active: category === "furrow-vision" })}
        >
          FurrowVision™
        </div>

        <div
          onClick={() => setCategory("see-and-spray")}
          className={classNames({ active: category === "see-and-spray" })}
        >
          See &amp; Spray™ Ultimate
        </div>
        <div
          onClick={() => setCategory("cotton-picker")}
          className={classNames({ active: category === "cotton-picker" })}
        >
          Cotton Picker
        </div>
        <div
          onClick={() => setCategory("road-building")}
          className={classNames({ active: category === "road-building" })}
        >
          Road Building
        </div>
        <div
          onClick={() => setCategory("operations-center")}
          className={classNames({ active: category === "operations-center" })}
        >
          Operations Center™
        </div>
      </div>
      {vidList.length === 0 && <div className="no-images">Coming Soon.</div>}
      {vidList.map((x) => (
        <div
          className="img-wrapper"
          key={x.path}
          onClick={() =>
            handleModal({
              lightbox: true,
              video: `${urlRoot}/vid/${x.category}/${x.path.replaceAll(
                " ",
                "%20"
              )}.mp4`,
            })
          }
        >
          <div
            className="img-thumb"
            style={{
              backgroundImage: `url(${urlRoot}/vid/${
                x.category
              }/${x.path.replaceAll(" ", "%20")}.jpg)`,
            }}
          />
          <div className="thumb-overlay">
            <span>View</span>
          </div>
        </div>
      ))}
    </div>
  );
};

import classNames from "classnames";

interface FactProps {
  className?: string;
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}
export const Fact = (props: FactProps) => {
  return (
    <div className={classNames("fact", props.className)}>
      <h3>{props.title}</h3>
      <div className="fact-wrapper">
        <div className="icon">{props.icon}</div>
        <div className="fact-content">{props.children}</div>
      </div>
    </div>
  );
};

import * as React from "react";
import AC3 from "./img/CES2024_InnovationAwardHonoree-2x.png";
import "./Accolades.scss";

interface IAccoladesProps {
  title: string;
}

export const Accolades: React.FunctionComponent<IAccoladesProps> = (props) => {
  const cesUrl =
    "https://www.ces.tech/Innovation-Awards/Honorees/2023/Best-Of/J/John-Deere-Autonomous-Tractor.aspx";

  return (
    <div className="Accolades">
      {props.title && <h2 className="green">{props.title}</h2>}
      <div className="flexHorizontal">
        <div className="image-side">
          <a href={cesUrl} target="_blank" rel="noreferrer">
            <img src={AC3} alt="" />
          </a>
        </div>
        <div className="text-side">
          <h4>CES INNOVATION AWARDS <span className="nobr">2024 HONOREE</span></h4>
          <h5>
            <span style={{ fontSize: "0.8em", fontWeight: "normal" }}>
              SUSTAINABILITY, ECO-DESIGN & SMART ENERGY
            </span>
          </h5>
          <hr />
          <h6><strong>5 CONSECUTIVE YEARS</strong> OF CES INNOVATION AWARD WINS</h6>
        </div>
      </div>
    </div>
  );
};

import classNames from "classnames";
import { ReactComponent as Logo } from "../home/img/real_logo.svg";
import { ReactComponent as Person } from "./img/CES-FactSheet-Icon_Person.svg";
import { ReactComponent as Corn } from "./img/CES-FactSheet-Icon_Corn.svg";
import { ReactComponent as Wheat } from "./img/CES-FactSheet-Icon_Wheat.svg";
import { ReactComponent as ElectricExcavator } from "./img/2023/ElectricExcavator-Icon.svg";
import { ReactComponent as ExactShot } from "./img/2023/ExactShot-Icon.svg";
import { ReactComponent as Farming } from "./img/2023/Farming-Icon.svg";
import { ReactComponent as FoodDemand } from "./img/2023/FoodDemand-Icon.svg";
// import { ReactComponent as GrowingPlanet } from "./img/2023/GrowingPlanet-Icon.svg";
import { ReactComponent as LaborShortage } from "./img/2023/LaborShortage-Icon.svg";
import { ReactComponent as SeeAndSpray } from "./img/2023/SeeAndSpray-Icon.svg";
import { ReactComponent as Sustainability } from "./img/2023/Sustainability-Icon.svg";
import "./FactSheet2Page.scss";
import { Fact } from "./Fact";
import { AttribLink } from "./AttribLink";
import { AnchorOutlineButton } from "../../components/OutlineButton";
import Footer from "../../components/Footer";

interface Props {}

export const FactSheet2Page = (props: Props) => {
  return (
    <div className={classNames("FactSheet2Page")}>
      <div className="page-content">
        <FactPageHeader />
        <div className="facts">
          <div className="div1">
            <h3>GROWING MORE FOR A GROWING PLANET</h3>
            <div className="icon">
              <Person />
              <Person />
              <Person />
              <Person />
              <Person />
              <Person />
              <Person />
              <div className="big-separator"> | </div>
              <Corn />
              <Wheat />
              <Corn />
              <Wheat />
              <Corn />
              <Wheat />
              <Corn />
            </div>
            <div className="fact-content">
              The United Nations estimates that in the next 30 years, population
              growth will require twice as much food and urban areas will house
              nearly 70% of the projected 10 billion people living on the
              planet.{" "}
              <AttribLink href="https://www.deere.com/assets/pdfs/common/our-company/sustainability/sustainability-report-2021.pdf">
                Deere
              </AttribLink>
            </div>
          </div>
          <div className="two-facts">
            <Fact title="Meeting Food Demand" icon={<FoodDemand />}>
              One U.S. farm alone feeds 166 people around the world every year.
              <AttribLink href="https://www.fb.org/newsroom/fast-facts">
                American Farm Bureau, 2020
              </AttribLink>
            </Fact>

            <Fact title="ExactShot" icon={<ExactShot />}>
              ExactShot technology allows farmers to reduce the amount of
              starter fertilizer needed during planting by more than 60%.
            </Fact>
          </div>
          <Fact title="LABOR SHORTAGE IS A BIG CHALLENGE" icon={<LaborShortage />} className="light">
            <ul>
              <li>
                Less than 2% of the U.S. population is actively involved in
                agricultural production.{" "}
                <AttribLink href="https://www.bls.gov/ooh/farming-fishing-and-forestry/agricultural-workers.htm">
                  U.S. Bureau of Labor Statistics, 2021
                </AttribLink>
              </li>
              <li>
                Overall employment of agricultural workers is projected to grow
                1% from 2019–2029, slower than the average for all occupations.
                <AttribLink href="https://www.bls.gov/ooh/farming-fishing-and-forestry/agricultural-workers.htm">
                  U.S. Bureau of Labor Statistics, 2021
                </AttribLink>
              </li>
              <li>
                Urban areas make up only 3% of the entire land area of the
                country but are home to more than 80% of the population.
                Conversely, 97% of the country’s land mass is rural, but only
                19.3% of the population lives there.
                <AttribLink href="https://www.census.gov/content/dam/Census/library/publications/2016/acs/acsgeo-1.pdf">
                  Defining Rural at the U.S. Census Bureau
                </AttribLink>
              </li>
              <li>
                The average farmer is over the age of 55 and putting in 12–18
                hours a day of work to grow food for all of us. This is not
                sustainable.{" "}
                <AttribLink href="https://www.nass.usda.gov/Publications/AgCensus/2017/Full_Report/Volume_1,_Chapter_1_US/st99_1_0052_0052.pdf">
                  NASS, USDA, 2017
                </AttribLink>
              </li>
              <li>
                Contractors are facing an uphill battle: to help close the
                world’s $15-trillion{" "}
                <a href="https://www.weforum.org/agenda/2019/04/infrastructure-gap-heres-how-to-solve-it/" target="_blank" rel="noreferrer">
                  infrastructure gap
                </a>{" "}
                by 2040, all while dealing with increasing variability,
                unpredictability, and adversity led by labor shortages, workflow
                inefficiencies, and finite resources.{" "}
                <AttribLink href="https://www.weforum.org/agenda/2019/04/infrastructure-gap-heres-how-to-solve-it/">
                  World Economic Forum
                </AttribLink>
              </li>
            </ul>
          </Fact>
          <div className="two-facts">
            <Fact title="FARMING IS A FAMILY BUSINESS" icon={<Farming />}>
              <ul>
                <li>
                  86% of U.S. agricultural products are produced on family farms
                  or ranches.{" "}
                  <AttribLink href="https://www.fb.org/newsroom/fast-facts">
                    American Farm Bureau Federation
                  </AttribLink>
                </li>
                <li>
                  Family farms account for almost 96% of the over 2.20 million
                  farms in the U.S.{" "}
                  <AttribLink href="https://nifa.usda.gov/family-farms">
                    USDA
                  </AttribLink>
                </li>
                <li>
                  John Deere Precision Ag Technology is used on farms of all
                  sizes, from 100–10,000 acres and more (one acre is about the
                  same size as a football field). These farms can have over 750
                  million plants.{" "}
                  <AttribLink href="https://www.deere.com/assets/pdfs/common/our-company/sustainability/sustainability-report-2020.pdf">
                    Deere
                  </AttribLink>
                </li>
              </ul>
            </Fact>
            <Fact title="SEE & SPRAY™" icon={<SeeAndSpray />}>
              <ul>
                <li>
                  See & Spray technology is so precise that it can recognize the
                  difference between plants even in conditions that would
                  challenge the human eye. It operates up to 12 mph via a
                  sprayer with a 120-foot boom that scans more than 2,100 square
                  feet per second.
                </li>
                <li>
                  With our See & Spray technology, farmers can now spray only
                  the weeds, which reduces the amount of herbicide farmers need
                  by up to 66%.
                </li>
                <li>
                  See & Spray has 36 cameras attached across the machine’s
                  120-foot carbon-fiber boom. These cameras scan more than 2,200
                  square feet of land and capture 1.2 billion pixels per second.
                  If you tried to match that level of sensing and processing
                  with human eyes, it would take nearly 6,000 people.
                </li>
              </ul>
            </Fact>
          </div>
          <div className="two-facts">
            <Fact title="EXCAVATOR" icon={<ElectricExcavator />} className="light">
              <ul>
                <li>
                  John Deere’s new electric excavator, powered by a Kreisel
                  battery, will improve the quality of work in construction.
                  Deere acquired a majority stake in Kreisel Electric, the
                  global leader in immersion-cooled battery technology, in 2021.
                </li>
                <li>
                  The batteries are modular, reducing the cost of ownership, and
                  use a charging infrastructure that costs about 50% less than
                  typical electric-vehicle (EV) fast chargers.
                </li>
              </ul>
            </Fact>
            <Fact title="PINPOINT SUSTAINABILITY" icon={<Sustainability />} className="light">
              <ul>
                <li>
                  The overall carbon intensity of biofuels produced from corn or
                  soybean feedstocks, such as ethanol and renewable diesel,
                  produces about 50% less greenhouse gas emissions compared to
                  petroleum-based fuels.
                </li>
                <li>
                  Over the course of one growing season, a farmer utilizing
                  foundational John Deere Precision Ag Technology avoids over
                  400,000 kg of CO2 emissions — the equivalent of over 992,000
                  passenger-car miles driven per year.{" "}
                  <AttribLink href="https://www.deere.com/assets/pdfs/common/our-company/sustainability/sustainability-report-2020.pdf">
                    Deere
                  </AttribLink>
                </li>
              </ul>
            </Fact>
          </div>
        </div>
      </div>
      <div className="download-link">
        <AnchorOutlineButton
          className="yellow"
          text="Download Fact Sheet"
          to={
            "https://jd-ces-2023.s3.amazonaws.com/media-resources/CES-FactSheet.pdf"
          }
        />
      </div>
      <Footer />
    </div>
  );
};

function FactPageHeader() {
  return (
    <div className="pageheader">
      Quick Facts{" "}
      <div>
        <Logo />
      </div>
    </div>
  );
}

import * as React from "react";
import { IExpertSlideProps } from "./MeetTheExperts";

export const EXPERTS_2022: IExpertSlideProps[] = [
  {
    name: "Jahmy Hindman",
    src: "JahmyHindman",
    title: "Chief Technology Officer",
    bio: "Jahmy Hindman, Ph.D., is the chief technology officer at John Deere, a position he has held since June 2020. He is responsible for building Deere’s “tech stack,” the company’s intuitive end-to-end equipment solution made up of hardware and devices, embedded software, connectivity, data platforms, and applications. He also leads the company’s Intelligent Solutions Group (ISG) and is responsible for the global network of technology and innovation centers and the shared engineering function. Jahmy has more than 20 years of advanced technology, artificial intelligence, product engineering, and manufacturing experience. He earned a bachelor’s degree in mechanical engineering from Iowa State University as well as master’s and doctoral degrees in mechanical engineering from the University of Saskatchewan.",
  },
  {
    name: "Josh Jepsen",
    src: "JoshJepsen",
    title: "Chief Financial Officer",
    bio: "Josh Jepsen is the chief financial officer at John Deere. He is responsible for advising the chief executive officer and other leaders on major financial and strategic issues, including efforts related to sustainability. Josh started his career 25 years ago with Deere at the company’s engine factory in Torreon, Mexico, and has held several roles globally in accounting and financial analysis as well as dealer development for the Construction & Forestry Division. He was controller for the company’s Asia-Pacific and Africa operations in Singapore, then served as manager of investor communications and eventually director of investor relations and deputy financial officer. Josh earned degrees in accounting and Spanish from the University of Northern Iowa and a master’s degree from the University of Michigan’s Ross School of Business.",
  },
  {
    name: "Justin Rose",
    src: "JustinRose",
    title: "President, Lifecycle Solutions, Supply Management, and Customer Success",
    bio: "Justin Rose is president of lifecycle solutions, supply management, and customer success. In this position, he is responsible for leading the company’s worldwide aftermarket, customer support, and supply management and logistics groups. He also oversees the achievement of recurring revenue and customer-success goals. Prior to joining John Deere, Justin spent 20 years at Boston Consulting Group (BCG), a leading international business-consulting firm, where he was a senior partner and managing director. There he worked with Deere on a series of high-profile projects and was instrumental in the development of the smart-industrial strategy and operating model."
  },
  {
    name: "Aaron Wetzel",
    src: "AaronWetzel",
    title: "Vice President, Production Systems",
    bio: "Aaron Wetzel is the vice president of Production and Precision Ag Production Systems at John Deere. With expertise in agronomy, economics, products, and technology, he leads a global team focused on developing Deere’s next generation of products and technology solutions that meet specific customer and market needs. Throughout his 35 years with Deere, he has contributed to the company’s success in various locations, including Germany and Latin America and in a variety of functions, including sales, marketing, strategic planning, and financial services. Aaron earned a bachelor’s degree in accounting from Augustana College and a master’s degree in business administration from Duke University.",
  },
  {
    name: "Lane Arthur",
    src: "LaneArthur",
    title: "Vice President, Embedded Software and Analytics",
    bio: "Lane Arthur, Ph.D., is the vice president of embedded software and solutions for the Intelligent Solutions Group (ISG) at John Deere. He oversees the development and commercialization of core precision agriculture solutions, including GNSS receivers, correction signals, displays, universal guidance systems, and telematics devices. Lane is also responsible for the global electronics group, a team that develops software and hardware systems, architecture, infrastructure, and operating systems for the enterprise. Before Deere, Lane held leadership roles at DuPont Pioneer®, including vice president and chief information officer, in which he oversaw the deployment of global systems that applied analytical methods and modeling with large data sets to solve business problems. Lane earned a bachelor’s degree in biochemistry from the University of Georgia and a Ph.D. in genetics and development from Columbia University Vagelos College of Physicians and Surgeons.",
  },

  {
    name: "Jorge Heraud",
    src: "JorgeHeraud",
    title: "Vice President, Automation and Autonomy",
    bio: "Jorge Heraud is the vice president of automation and autonomy within the Intelligent Solutions Group (ISG) at John Deere. He leads the development of automation and autonomy products that make Deere customers more productive, profitable, and sustainable. Prior to his career with the company, Jorge was the CEO and co-founder of Blue River Technology (a John Deere company), which was acquired by Deere & Company in September 2017 and is now run as an independent subsidiary. He earned a bachelor’s degree in electrical engineering from Universidad Catolica del Peru and three master’s degrees in electrical engineering, engineering management, and business from Stanford University.",
  },
  {
    name: "Sarah Schinckel",
    src: "SarahSchinckel",
    title: "Director, Emerging Tech",
    bio: "Sarah Schinckel is the director of emerging technologies in the Intelligent Solutions Group (ISG) at John Deere. Her team focuses on researching, developing, and supporting the deployment of Deere’s next-generation technologies to improve customer profitability and sustainability. She has 20 years of experience with software development for web and embedded systems, and has worked in a variety of roles across development, systems engineering, and engineering management. Sarah earned a bachelor’s degree in computer science, a master’s degree in engineering management from Iowa State University, and graduate certificates from Iowa State University and the Massachusetts Institute of Technology."
  },
  {
    name: "Jon Chase",
    src: "JonChase",
    title: "Vice President, Marketing and Shared Services",
    bio: "Jon Chase is the director of marketing and shared services for John Deere’s Ag & Turf Division. With his team, he focuses on transforming Deere’s business by building and delivering world-class marketing capabilities and enhanced go-to-market support for the company’s North American dealers. Throughout his 22 years at Deere, Jon has worked in all Ag & Turf segments, including turf, small ag, and large ag as well as multiple operations and marketing positions throughout the U.S. and Germany. In addition, he worked in Deere’s Construction & Forestry Division, where he was responsible for the production of the excavator product line. Jon earned a bachelor’s degree in engineering from Kettering University and a master’s degree in business administration from Harvard Business School.",
  },


  {
    name: "Heather Van Nest",
    src: "HeatherVanNest",
    title: "Director, South American Production and Precision Agriculture",
    bio: "Heather Van Nest is the director of South American Production and Precision Ag at John Deere. She leads a team that develops and markets precision agriculture solutions in South America. Throughout her 20-year career with Deere, she has held many roles in sales, marketing, and aftermarket, and has extensive experience driving dealer channel strategies for customer adoption and utilization. Heather earned a bachelor’s degree from Iowa State University and a master’s degree in business administration from Indiana University Kelly School of Business.",
  },
  {
    name: "Doug Sauder",
    src: "DougSauder",
    title: "Director, Product Management and User Experience",
    bio: "Doug Sauder is the director of product management and user experience for the Intelligent Solutions Group (ISG) at John Deere. He leads the product vision, definition, and user experience for integrated technology solutions and digital products that help farmers and contractors be more productive, profitable, and sustainable. Doug takes a solutions approach to solving customer problems, partnering with engineering and data science teams to leverage cutting-edge technology to solve some of the world’s biggest challenges. Prior to joining Deere, he led product development teams at Precision Planting, where he built agricultural equipment solutions, and The Climate Corporation, where he built digital tools for farmers. Doug earned a bachelor’s degree in business administration from the University of Phoenix.",
  },

  {
    name: "Chris Winkler",
    src: "ChrisWinkler",
    title: "Director, Digital Software and Solutions",
    bio: "Chris Winkler is the director of digital software and solutions for the Intelligent Solutions Group (ISG) at John Deere. He leads global teams in building and supporting cloud-based software platforms that allow customers, dealers, and third-party solution providers to leverage data, models, and insights. Before joining Deere, Chris was vice president of engineering at Granular, an agriculture technology company, and held various roles at DuPont Pioneer®, where he developed novel analytical methods and models for advancing plant-breeding programs and precision agriculture. Chris earned a bachelor’s degree in physics from Iowa State University and a master’s degree in meteorology from the Massachusetts Institute of Technology.",
  },
  {
    name: "Travis Becton",
    src: "TravisBecton",
    title: "Director, Cotton and Sugar Production Systems",
    bio: "Travis Becton is the global director for the cotton and sugar production systems at John Deere. He leads a team that drives business financials and creates solutions focused on unlocking new value for customers. He has 28 years of experience in marketing, sales, and customer experience roles in the agriculture and turf space at Deere. Travis earned a bachelor’s degree from Texas Tech University in agricultural economics and a master’s degree in business administration from Purdue University."
  },
  {
    name: "Joel Dawson",
    src: "JoelDawson",
    title: "Director, Production and Precision Ag Marketing",
    bio: "Joel Dawson is the director of Production and Precision Ag marketing at John Deere. In this role, he leads a team that executes all go-to-market activities for production and precision agriculture equipment and technology for North America. Throughout his 21 years at Deere, he has worked to shape the company’s strategy for technology integration by creating and executing channel strategies that deliver on customers’ dynamic technology needs. Joel earned a bachelor’s degree in marketing and supply management from Western Illinois University and a master’s degree in business administration from the University of Phoenix."
  },
  {
    name: "Kevin Very",
    src: "KevinVery",
    title: "Manager, Earthmoving Production Systems and Product Management",
    bio: "Kevin Very is the manager of earthmoving production systems and product management in the John Deere Construction & Forestry Division. With expertise in civil engineering, construction management, and technology, he leads a team focused on identifying technology-forward solutions to unlock sustainable value for earthmoving customers. Kevin earned a bachelor’s degree in civil engineering from North Dakota State University and a master’s degree in business administration from Duke University."
  },
  {
    name: "Maryanne Graves",
    src: "MaryanneGraves",
    title: "Manager, Product Management Construction Equipment",
    bio: "Maryanne Graves is the global manager of product management for construction equipment at John Deere. She leads a team focused on solving construction customers’ needs for smarter, safer, and more sustainable construction equipment. Throughout her 19 years at Deere, she has held numerous positions in product support, training, marketing, product management, and automation. Maryanne earned a bachelor’s degree in marketing and management from the University of Iowa Tippie College of Business.",
  },
  {
    name: "Brianna Heim",
    src: "BriannaHeim",
    title: "Manager, Planter Product Engineering",
    bio: "Brianna Heim is the engineering manager for planters within the John Deere Crop Care Division. She and her team lead the development of product engineering solutions for the global planter product line. In her 25 years at Deere, she has held multiple roles within product engineering and has had the opportunity to lead the development of innovative solutions that bring high value to the company’s customers, including the ExactEmerge™ technology. Brianna earned a bachelor’s degree in agricultural engineering from the University of Illinois."
  },
  {
    name: "Estela Dias",
    src: "EstelaDias",
    title: "Manager, South American Go-to-Market Production Systems",
    bio: "Estela Dias is the South American go-to-market manager for precision agriculture production systems at John Deere. With her expertise in agronomy, technology, and the Brazilian market, she leads a team of specialists to help farmers increase efficiency, profitability, and sustainability by using auto steer, sensors, artificial intelligence, machine learning, and data. With more than 18 years of experience in the agricultural industry, Estela is passionate about equipping farmers with technology to produce the food, fiber, and fuel we all depend on. Throughout her seven years with Deere, she has held positions across marketing, product management, and business development, including within the sugar and ethanol segment. Estela earned a bachelor’s degree in agronomy from the São Paulo University – ESALQ and a master’s degree in business administration at ESALQ-PECEGE in Brazil.",
  },
  {
    name: "Tammo Wagner",
    src: "TammoWagner",
    title: "Global Manager, Construction & Forestry Training",
    bio: "Tammo Wagner is the global manager of training within John Deere’s Construction & Forestry Division. In this role, he oversees the division’s global network of training centers and demonstration sites. Throughout his 17 years with Deere, he has worked in the construction and agriculture divisions in North America, South America, Europe, Asia, and Australia in roles spanning across marketing, sales, engineering, finance, and technology. Tammo earned a graduate degree in mechanical engineering from Mannheim University in Germany, a master’s of science degree from Iowa State University, a master’s degree in business administration from Northwestern University’s Kellogg School of Management, and executive education certificates from the University of Pennsylvania.",
  },
  {
    name: "Jonny Spendlove",
    src: "JonnySpendlove",
    title: "Manager, Precision Construction",
    bio: "Jonny Spendlove is the manager of Precision Construction at John Deere. He leads a team of product managers developing jobsite safety, grade management, autonomy, connectivity, and automation solutions. Throughout his 12 years with Deere, he has worked in sales, marketing, and product development roles for agriculture and construction equipment. Jonny earned bachelor’s degrees in English and economics from the University of Utah and a master’s degree in business administration from the University of Chicago."
  },
  {
    name: "Oriana Lisker Bosin",
    src: "OrianaLiskerBosin",
    title: "Product Manager, Sustainability Solutions",
    bio: "Oriana Lisker Bosin is the group product manager of sustainability solutions at John Deere. In this role, she crafts and owns the vision, strategy, and road map to help farmers improve their soil health, reduce their carbon emissions, and increase their profitability. Oriana has extensive experience across a range of early-stage ventures in sustainability, digital agriculture, and large-scale sensor data, harnessing this knowledge to bring environmentally efficient practices to customers. She earned a bachelor’s degree in chemistry and computer science from Duke University and a master’s degree in agricultural economics and sustainability from the University of California, Davis."
  },
  {
    name: "Mike Kool",
    src: "MikeKool",
    title: "Senior Product Manager, Connected Fleet",
    bio: "Mike Kool is the senior product manager of connected fleet at John Deere. He leads a team of project managers and engineers working on connectivity, specifically the vision and strategy for connected fleet solutions to help the company achieve its goal of connecting 1.5 million machines by 2026. Throughout his 12 years with Deere, Mike has worked in supply management, telecommunications, connectivity, and product development roles for agriculture and construction equipment. He earned his bachelor’s degree in sociology from the University of Illinois at Chicago."
  },
  {
    name: "Rekha Gore",
    src: "RekhaGore",
    title: "Global Manager, Embedded Infrastructure and Tools",
    bio: "Rekha Gore is the global manager of embedded infrastructure and tools in the Intelligent Solutions Group (ISG) at John Deere. She is responsible for leading the development of operating systems for embedded controllers and displays as well as key technologies and infrastructure for vehicle communication networks, diagnostics, and reprogramming services, tech stack license management, and activations services. Throughout her 18 years with Deere, Rekha has held various roles in the engineering in embedded domain. Before joining the company, she worked for TATA Motors, an automotive company in India. Rekha earned her bachelor’s degree in electronics and telecommunications from Pune University, an executive post-graduate diploma in business management from Symbiosis International University, and a system design and management certificate from the Massachusetts Institute of Technology."
  },
  {
    name: "Jeff Spencer",
    src: "JeffSpencer",
    title: "Senior Product Manager, Planting Automation",
    bio: "Jeff Spencer is a senior technology product manager in planting automation for the Intelligent Solutions Group (ISG) at John Deere. With more than 15 years of experience developing agriculture technology solutions, he leads the development and execution of product strategies to make Deere’s planters capable of performing superhuman tasks. Jeff recently helped deploy FurrowVision™, one of the company’s latest technologies, to help customers improve the job of planting. He earned a bachelor’s degree in chemical engineering from the University of Missouri.",
  },
  {
    name: "Polina Aronova",
    src: "PolinaAronova",
    title: "Program Manager, Autonomy",
    bio: "Polina Aronova is a program manager for large agriculture autonomy at John Deere. She leads multiple autonomy programs focused on developing intelligent solutions to help customers manage their operations anytime, anywhere. Throughout her 23 years with Deere, she has worked with various product development teams in harvesting, seeding, and power systems, and supported start-up manufacturing operations. Polina earned a bachelor’s degree in economics, sociology, and law from the University of Moscow and a master’s degree in business administration from the University of Northern Iowa.",
  },
  {
    name: "Michele Kaiser",
    src: "MicheleKaiser",
    title: "Chief of Staff and Portfolio Manager",
    bio: "Michele Kaiser is the chief of staff and portfolio manager for the Intelligent Solutions Group (ISG) at John Deere. Her team proactively strategizes, communicates, and plans portfolios for the ISG business, ensuring that Deere deploys its technology stack resources against top enterprise ambitions. In this role, she also ensures Deere’s technology is effectively used across multiple platforms to reach the company’s Leap Ambitions across production systems to deliver value to Deere’s customers. Michele earned a bachelor’s degree in mechanical engineering from the University of Dayton and master’s degrees in engineering management and business administration from the University of Wisconsin–Madison.",
  },
  {
    name: "Steve Young",
    src: "SteveYoung",
    title: "Manager, Cotton Production System",
    bio: "Steve Young is the cotton production system manager for John Deere. With his team, he serves as the link between Deere and cotton growers, striving to understand the intricacies of their businesses. With this knowledge, his team identifies cutting-edge machinery and technology solutions to enhance efficiency for the long-term profitability and sustainability of cotton farming globally. Throughout his 27 years with Deere, Steve has worked in a variety of roles and locations throughout the U.S. and gained exposure to global markets through extensive travel and customer visits. He also has experience in factory, product line, and marketing positions, including field sales and territory roles. Steve earned a bachelor’s degree in agricultural economics from Kansas State University and a systems engineering fundamentals certificate from the California Institute of Technology.",
  },
  {
    name: "Filip Reese",
    src: "FilipReese",
    title: "Director of Product Management, Autonomy",
    bio: "Filip Reese is the director of product management for Large Ag Autonomy at John Deere and Blue River Technology (a John Deere company). In this role, he is responsible for the strategy and delivery of autonomous solutions across the production cycle. Filip has more than a decade of product management and marketing experience in the technology industry. For the last six years, he has led teams in the development of Deere and Blue River’s flagship artificial intelligence and robotics products. Prior to joining Deere and Blue River, Filip held product management and marketing roles for the venture-backed, high-growth startups 99designs (acquired by VistaPrint®) and Culture Amp. He earned a bachelor’s degree in international relations at the University of California, Davis and a master’s degree in business administration from the University of Melbourne.",
  },
  {
    name: "Maya Sripadam",
    src: "MayaSripadam",
    title: "Senior Product Manager, Autonomy",
    bio: "Maya Sripadam is a senior product manager for autonomy at Blue River Technology (a John Deere company). In this role, she and her team develop autonomous solutions for Deere’s earthmoving division. Prior to this role, Maya led product development initiatives across Blue River to deliver world-changing artificial intelligence products to farmers. Her work spans from ideation to execution, with a focus on connecting top engineering minds to customer needs. She most recently contributed to Deere’s core autonomous tractor system. Maya earned a bachelor’s degree in information systems from Carnegie Mellon University.",
  },
  {
    name: "Aaron Ticknor",
    src: "AaronTicknor",
    title: "Product Manager, Large Ag Autonomy",
    bio: "Aaron Ticknor is a product manager for large ag autonomy at John Deere. He works across teams at the company to combine machinery and technology to solve some of the most challenging problems in agriculture. Throughout his 16 years with Deere, Aaron has worked across many areas of the business, from product development to operations and customer support to product marketing and management. He has brought several global product programs to life, including flagship tractor programs and vehicle autonomy. Aaron earned an associate’s degree in electronics engineering technology from Hawkeye Community College and a bachelor’s degree in technology management from the University of Northern Iowa.",
  },
  {
    name: "Sean Mairet",
    src: "SeanMairet",
    title: "Group Product Manager, Grade Management",
    bio: "Sean Mairet is a group product manager for grade management in the John Deere Construction & Forestry (C&F) Division. He leads a group of Precision Construction specialists responsible for selling and supporting the C&F portfolio of grade management solutions for crawler dozers, motor graders, excavators, and compact track loaders. As a product manager, he also works with customers and dealers to support the development of grade management solutions coming to market. Sean has more than 15 years of experience in engineering and the development of backhoe loaders, skid steer loaders, and compact track loaders before moving into grade control product marketing and eventually into product management. He earned a bachelor’s degree in mechanical engineering from the University of Iowa and a master’s degree from the University of Dubuque.",
  },
  {
    name: "Michael Klein",
    src: "MichaelKlein",
    title: "Product Manager, Digital Products, Wirtgen Group",
    bio: "Michael Klein is a product manager for digital products at Wirtgen Group (a John Deere company). In this role, he is responsible for jobsite intelligence within the John Deere Operations Center™ and go-to-market strategies for Wirtgen Group’s digital solutions. his work spans from defining business models to creating customer solutions. Before Wirtgen Group, Michael was a product marketing manager at mobile crane manufacturer Terex®. He earned a bachelor’s degree in economic engineering from the Technical University of Kaiserslautern, Germany.",
  },
  {
    name: "Jessica Uelmen",
    src: "JessicaUelmen",
    title: "Senior Director of Engineering, See & Spray™",
    bio: "Jessica Uelmen is the senior director of engineering for See & Spray at Blue River Technology (a John Deere company). In this role, she leads development across computer vision, machine learning, robotics, and embedded software, hardware, and systems. Prior to Blue River, she held tenures at a variety of Silicon Valley companies, including Udacity, Fitbit, and Cruise. Jessica earned a bachelor’s degree in electrical engineering technology from the University of Central Florida and a master’s degree in engineering engagement from the University of Wisconsin–Madison.",
  },
  {
    name: "Kelly Sikkema",
    src: "KellySikkema",
    title: "Manager, Digital Experience",
    bio: "Kelly Sikkema is the manager of digital user experience at John Deere. In this role, she helps define the experience of world-class products at the intersection of design, data, and interactions. Over her 16 years with Deere, one of Kelly’s most meaningful projects was designing the framework for some of the company’s first mobile applications. During this time, she learned how customers expect to see information inside and outside the cab and the challenges of an ever-changing technology landscape. Kelly earned a bachelor’s degree in communications and marketing from St. Ambrose University and is a Certified Usability Analyst.",
  },
  {
    name: "Kim Duhn",
    src: "KimDuhn",
    title: "Senior Product Manager, Agronomic Analysis",
    bio: "Kim Duhn is a senior product manager at John Deere, specializing in agronomic analysis. She creates solutions for the John Deere Operations Center™ that empower farmers to make informed decisions, enhancing profitability and productivity on their farms. Throughout her 15-year career at Deere, Kim has held various roles in product and project management focused on delivering valuable solutions that meet the unique needs of farmers. She earned a bachelor’s degree in logistics, operations, and supply chain management from Iowa State University.",
  },
  {
    name: "Kyle Plattner",
    src: "KylePlattner",
    title: "Senior Product Manager, Mobile and Monitoring",
    bio: "Kyle Plattner is a senior product manager at John Deere, focused on mobile and monitoring for the John Deere Operations Center™. In this role, he builds tools that optimize productivity and job quality across large agriculture operations. The Operations Center mobile app and monitoring pipeline provide live data to farm managers so they can make confident, informed decisions to increase productivity. Prior to Deere, Kyle worked for The Climate Corporation, also developing mobile apps for farmers. He earned a bachelor’s degree in computer science from Eureka College and a master’s degree from Robert Morris University.",
  },
  {
    name: "Tim Marquis",
    src: "TimMarquis",
    title: "Senior Product Manager, Precision Agriculture Analytics",
    bio: "Tim Marquis is the senior product manager for precision agriculture analytics at John Deere. A seasoned technologist, he focuses on using artificial intelligence and machine learning to create products in the John Deere Operations Center™ that drive profitable and sustainable outcomes for farmers. For example, Tim and his team launched the first live machine learning model in the Operations Center that accurately predicts the remaining time for farming operations, allowing better fleet management. His background is in meteorology, but he shifted his focus to agtech 10 years ago by launching products that integrate weather data into farm management platforms. Tim earned a bachelor’s degree in meteorology from the University of Oklahoma and a master’s degree in meteorology from Iowa State University.",
  },
  {
    name: "Bret Culpepper",
    src: "BretCulpepper",
    title: "Senior Systems Engineer",
    bio: "Bret Culpepper is a senior systems engineer for displays at John Deere. He serves as a liaison between the Intelligent Solutions Group (ISG) and the factories that adopt and program displays. Bret also provides technical support for new display software releases multiple times a year. Throughout his 15-year career with Deere, he has held many roles, including safety lead on the delivery of Deere’s first autonomous 8R tractor, a system engineer helping develop machine automation camera systems, an engineering program manager for engine systems, an engine software calibration engineer, and a manufacturing engineer for hydraulic components. Bret earned a bachelor’s degree in electrical engineering technology from the University of Northern Iowa and a master’s degree in engineering management from the University of Wisconsin–Madison.",
  },
  {
    name: "I-Sha Davis",
    src: "IShaDavis",
    title: "Automation System Test Engineer",
    bio: "I-Sha Davis is a system verification engineer for Combine Advisor™, John Deere’s combine automation suite to automatically maintain machine settings to improve grain quality and reduce loss. Throughout her eight years with Deere, she has been responsible for fire mitigation, design projects, and testing automation systems, including Auto Maintain, Active Terrain Adjustment™, HarvestSmart™, Optimize Performance™, and ActiveVision™ cameras. I-Sha earned a bachelor’s degree in mechanical engineering from the University of Wisconsin–Madison.",
  },
  {
    name: "Rick Theilen",
    src: "RickTheilen",
    title: "Staff Engineer",
    bio: "Rick Theilen is a staff systems engineer in the cotton harvesting group at John Deere. He focuses on integrating software control systems into cotton pickers and cotton strippers. Throughout Rick’s 23-year career at Deere, he was integral to the development of the TruSet™ solution for tillage, which automates tillage intensity based on user or prescription inputs. He also supported the development of the TruSet Seeding™, SeedStar™, PitStop Pro™, and cotton software control systems. Rick earned a bachelor’s degree in industrial technology from Iowa State University.",
  },
  {
    name: "Josh Minor",
    src: "JoshMinor",
    title: "Cotton Test Lead",
    bio: "Josh Minor is a cotton test lead at John Deere. He works with Deere dealers and customers to conduct agronomic field trials to validate the agronomic value of the company’s products across cotton-growing regions. Throughout his seven-year career with Deere, he has worked in several roles that have focused on precision agriculture technology and supporting dealers and customers across the Southern U.S. Josh earned a bachelor’s degree in agriculture from Eastern Kentucky University and a master’s degree in agribusiness from the University of Georgia.",
  },
  {
    name: "Miles Chiotti",
    src: "MilesChiotti",
    title: "Manager, North American Public & Industry Relations",
    bio: (
      <>
        <div><a href="mailto:ChiottiMiles@JohnDeere.com">ChiottiMiles@JohnDeere.com</a></div>
      </>
    ),
  },
  {
    name: "Franklin Peitz",
    src: "FranklinPeitz",
    title: "Public Relations Manager, Technology and Innovation",
    bio: (
      <>
        <div><a href="mailto:PeitzFranklinJ@JohnDeere.com">PeitzFranklinJ@JohnDeere.com</a></div>
      </>
    ),
  },
];

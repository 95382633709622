import * as React from "react";

import "./LivePage2023.scss";
import classNames from "classnames";
import Footer from "../../components/Footer";
import { Accolades } from "../media/Accolades";
import { ReactComponent as Logo } from "./img/real_logo.svg";
import { AnchorOutlineButton } from "../../components/OutlineButton";
import { ProgramSchedule } from "./ProgramSchedule";

interface LivePage2023Props {}

export const TeaserHero: React.FunctionComponent = () => {
  return (
    <div className="TeaserHero">
      <video className="TeaserHero__video" autoPlay muted loop playsInline>
        <source
          src="https://jd-ces-2024.s3.amazonaws.com/video/hero.mp4"
          type="video/mp4"
        />
      </video>
      <div className="TeaserHero__content">
        <Logo className="RealLogo" />
      </div>
    </div>
  );
};

export const FancyHr: React.FunctionComponent = () => {
  return (
    <PadPanel>
      <div className="FancyHr"></div>
    </PadPanel>
  );
};

export const PipeJoined: React.FunctionComponent<{ parts: string[] }> = (
  props
) => {
  return (
    <span className="PipeJoined">
      {props.parts.map((part, i) => (
        <span key={i}>
          {part}
          {i < props.parts.length - 1 ? (
            <span className="PipeJoined__pipe"></span>
          ) : null}
        </span>
      ))}
    </span>
  );
};

export const DateTimeCallout: React.FunctionComponent = () => {
  return (
    <>
      <div className="DateTimeCallout">
        <div className="DateTimeCallout__content">
          <h2 className="DateTimeCallout__title">
            Visit the <span className="nobr">John Deere</span> Booth
          </h2>
          <PadPanel>
            <AnchorOutlineButton className="yellow-outline" to="https://jd-ces-2024.s3.amazonaws.com/media-resources/jd-ces2024-map.jpg" text="View Booth Location"/>
          </PadPanel>
        </div>
      </div>
      <h2 className="DateTimeCallout__copy">
        <PipeJoined parts={["January 9-12, 2024", "Booth 4541 in West Hall", "Las Vegas Convention Center"]} />
      </h2>
    </>
  );
};

export const PadPanel: React.FunctionComponent<{ children: React.ReactNode }> = (props) => {
  return <div className="PadPanel">{props.children}</div>;
};

export const LivePage2023: React.FunctionComponent<LivePage2023Props> = (
  props
) => {
  return (
    <div className={classNames("LivePage2023")}>
      <TeaserHero />
      <PadPanel>
        <FancyHr />
          <div className="page-intro">John Deere at CES<sup>®</sup> 2024</div>
      </PadPanel>
      <DateTimeCallout />
      <FancyHr />
      <ProgramSchedule />
      <Accolades title="Accolades" />
      <Footer />
    </div>
  );
};

import classNames from "classnames";
import * as React from "react";
import "./PressReleases.scss";

interface IPressReleaseExpanderProps {
  title: string | JSX.Element;
  children: React.ReactNode;
}

export const PressReleaseExpander: React.FunctionComponent<
  IPressReleaseExpanderProps
> = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div className={classNames("PressReleaseExpander", { expanded })}>
      <div className="HeaderBar" onClick={() => setExpanded(!expanded)}>
        <span>{props.title}</span>
        <div className={classNames("Expander", { expanded })} />
      </div>
      <div className={classNames("Body", { expanded })}>{props.children}</div>
    </div>
  );
};

interface IPressReleasesProps {}

export const PressReleases: React.FunctionComponent<IPressReleasesProps> = (
  props
) => {
  return (
    <div className={classNames("PressReleases")}>
      <div className="Expanders">
        <PressReleaseExpander
          title={
            <span>
              JOHN DEERE OPERATIONS CENTER NAMED CES 2024 INNOVATION AWARDS HONOREE
            </span>
          }
        >
          <p>
            <strong>MOLINE, Illinois (November 16, 2023)</strong> – John Deere
            has been named a CES<sup>®</sup> 2024 Innovation Awards Honoree in the
            Sustainability, Eco-Design & Smart Energy category for the John
            Deere Operations Center™ (Operations Center) Sustainability Tools.
            This is the fifth consecutive year John Deere has received an
            Innovation Award from the Consumer Technology Association (CTA).
          </p>
          <p>
            The John Deere Operations Center™ is an opt-in cloud platform that
            empowers farmers to create optimized work plans, monitor job
            quality, and analyze and receive insights from data anytime and
            anywhere. The platform’s new Sustainability Tools provide farmers
            with actionable and personalized insights into key aspects of their
            operations, including carbon intensity, soil health, and fuel
            emissions, so they can make sustainable decisions to help feed,
            fuel, and clothe the world. The platform enables farmers to leverage
            a range of sustainability-focused features, enroll in voluntary
            revenue-generating programs, and share resulting insights seamlessly
            with trusted advisors.
          </p>
          <p>
            “Farmers care deeply about their land and work to manage it
            sustainably because it’s their livelihood and their legacy,” said
            Jahmy Hindman, chief technology officer for John Deere. “The new
            Sustainability Tools within the John Deere Operations Center™ allow
            farmers better visibility into their farming practices so they can
            understand the impact their decisions have on the sustainability of
            their operations. Arable land is not an unlimited resource and these
            new tools give farmers the ability to make informed decisions that
            not only benefit themselves, but the next generation of farmers and
            the world that relies upon them.”
          </p>
          <p>
            The Sustainability, Eco-Design & Smart Energy category of the
            Innovation Awards highlights nuanced features incorporated into
            products that facilitate environmentally friendly behavior, like
            efficient and clean energy use, the reduction of harmful substances,
            and promotion of renewable resources.
          </p>
          <p>
            The CES Innovation Awards program, owned and produced by the CTA, is
            an annual competition honoring outstanding design and engineering in
            29 product categories. Judges reviewed submissions based on
            innovation, engineering and functionality, aesthetics, and design.
            The honorees were unveiled before CES 2024, which takes place
            January 9-12 in Las Vegas, NV.
          </p>
          <h4>About John Deere</h4>
          <p>
            Deere & Company (www.JohnDeere.com) is a global leader in the
            delivery of agricultural, construction, and forestry equipment. We
            help our customers push the boundaries of what’s possible in ways
            that are more productive and sustainable to help life leap forward.
            Our technology-enabled products including John Deere Autonomous 8R
            Tractor, See & Spray™, and E-Power Backhoe are just some of the ways
            we help meet the world’s increasing need for food, shelter, and
            infrastructure. Deere & Company also provides financial services
            through John Deere Financial. For more information on Deere &
            Company, visit us at www.deere.com/en/news/.
          </p>
          <h4>Contact:</h4>
          <p>
            Franklin Peitz <br />
            Public Relations Manager, Technology &amp; Innovation <br />
            <a href="mailto:PeitzFranklinJ@JohnDeere.com">
              PeitzFranklinJ@JohnDeere.com
            </a>
          </p>
        </PressReleaseExpander>
      </div>
    </div>
  );
};
